export const useAmplitudeStore = defineStore("amplitude", {
  state: () => ({ deviceId: "" as string }),

  actions: {
    setDeviceId(deviceId: string) {
      this.deviceId = deviceId;
    },
  },

  getters: {
    getDeviceId(): string {
      return this.deviceId;
    },
  },
});
