
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as privacy_45policyQrNFZZ9PPAMeta } from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/articles/privacy-policy.vue?macro=true";
import { default as termsrhSYr5jmQMMeta } from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/articles/terms.vue?macro=true";
import { default as _91id_932OE7XgmcFwMeta } from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/campaign/[id].vue?macro=true";
import { default as campaignsSS8gwRByCSMeta } from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/campaigns.vue?macro=true";
import { default as indexa40aSfbb7XMeta } from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/index.vue?macro=true";
import { default as marinadeMgSDf18QniMeta } from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/landing/marinade.vue?macro=true";
import { default as metaplexBlDdXVf1GCMeta } from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/landing/metaplex.vue?macro=true";
import { default as switchboard0n41sjqU5EMeta } from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/landing/switchboard.vue?macro=true";
import { default as not_45allowedfXsooE3rOgMeta } from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/not-allowed.vue?macro=true";
import { default as past_45campaigns0ZHNgKKH69Meta } from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/past-campaigns.vue?macro=true";
import { default as index05ogp4lg6tMeta } from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/profile/index.vue?macro=true";
import { default as settingsJS4tIuxbX2Meta } from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/profile/settings.vue?macro=true";
import { default as verifynOyJC3c8EBMeta } from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/verify.vue?macro=true";
export default [
  {
    name: "articles-privacy-policy",
    path: "/articles/privacy-policy",
    meta: privacy_45policyQrNFZZ9PPAMeta || {},
    component: () => import("/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/articles/privacy-policy.vue")
  },
  {
    name: "articles-terms",
    path: "/articles/terms",
    meta: termsrhSYr5jmQMMeta || {},
    component: () => import("/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/articles/terms.vue")
  },
  {
    name: "campaign-id",
    path: "/campaign/:id()",
    component: () => import("/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/campaign/[id].vue")
  },
  {
    name: "campaigns",
    path: "/campaigns",
    component: () => import("/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/campaigns.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/index.vue")
  },
  {
    name: "landing-marinade",
    path: "/landing/marinade",
    meta: marinadeMgSDf18QniMeta || {},
    component: () => import("/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/landing/marinade.vue")
  },
  {
    name: "landing-metaplex",
    path: "/landing/metaplex",
    meta: metaplexBlDdXVf1GCMeta || {},
    component: () => import("/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/landing/metaplex.vue")
  },
  {
    name: "landing-switchboard",
    path: "/landing/switchboard",
    meta: switchboard0n41sjqU5EMeta || {},
    component: () => import("/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/landing/switchboard.vue")
  },
  {
    name: "not-allowed",
    path: "/not-allowed",
    meta: not_45allowedfXsooE3rOgMeta || {},
    component: () => import("/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/not-allowed.vue")
  },
  {
    name: "past-campaigns",
    path: "/past-campaigns",
    component: () => import("/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/past-campaigns.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/profile/index.vue")
  },
  {
    name: "profile-settings",
    path: "/profile/settings",
    component: () => import("/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/profile/settings.vue")
  },
  {
    name: "verify",
    path: "/verify",
    meta: verifynOyJC3c8EBMeta || {},
    component: () => import("/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/pages/verify.vue")
  }
]