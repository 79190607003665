import revive_payload_client_YTGlmQWWT5 from "/home/runner/work/evangelist-platform/evangelist-platform/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/home/runner/work/evangelist-platform/evangelist-platform/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/home/runner/work/evangelist-platform/evangelist-platform/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/home/runner/work/evangelist-platform/evangelist-platform/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PKIXU5KmsI from "/home/runner/work/evangelist-platform/evangelist-platform/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/home/runner/work/evangelist-platform/evangelist-platform/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/home/runner/work/evangelist-platform/evangelist-platform/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Ib6KnAhqrq from "/home/runner/work/evangelist-platform/evangelist-platform/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/home/runner/work/evangelist-platform/evangelist-platform/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import amplitude_client_RJajK1QYBz from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/plugins/amplitude.client.ts";
import app_insights_client_UdCsmfLVu1 from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/plugins/app-insights.client.ts";
import event_bus_lrIMUlWjzF from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/plugins/event-bus.js";
import prototypes_GSu5VWf6jF from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/plugins/prototypes.js";
import vue_tippy_ZTosugnBbO from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/plugins/vue-tippy.js";
import vue_toastification_nbRgz4qVB7 from "/home/runner/work/evangelist-platform/evangelist-platform/apps/creator-frontend/src/plugins/vue-toastification.js";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  navigation_repaint_client_PKIXU5KmsI,
  check_outdated_build_client_LIYcCMJD18,
  chunk_reload_client_SeG0EjL5Ec,
  plugin_vue3_Ib6KnAhqrq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  amplitude_client_RJajK1QYBz,
  app_insights_client_UdCsmfLVu1,
  event_bus_lrIMUlWjzF,
  prototypes_GSu5VWf6jF,
  vue_tippy_ZTosugnBbO,
  vue_toastification_nbRgz4qVB7
]